@use 'mixins' as *;

.title {
  font-size: var(--font-size-header2);
  line-height: var(--line-height-header2);
  font-weight: var(--font-weight-bold);
  @include breakpoint-min(medium) {
    font-size: var(--font-size-header1);
    line-height: var(--line-height-header1);
  }
}

.description {
  font-size: var(--font-size-header2);
  line-height: var(--line-height-header2);
  @include breakpoint-min(medium) {
    font-size: var(--font-size-header1);
    line-height: var(--line-height-header1);
  }
}

.title,
.description {
  &.white {
    text-shadow: 0 0 0.25ch var(--color-text-shadow-black);
  }
}
