@use 'mixins' as *;
@use 'sass:color';

.image-mobile {
  display: block;
  @include breakpoint-min(small) {
    display: none;
  }
}

.image-tablet {
  display: none;

  @include breakpoint-min-max(small, medium) {
    display: block;
  }
}

.image-desktop {
  display: none;

  @include breakpoint-min(medium) {
    display: block;
  }
}

.image-wrapper {
  position: absolute;
  inset: 0;
  background-image: var(--background-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.inner {
  position: relative;
  z-index: 1;

  --content-width-narrow: var(--content-width-medium);
  .has-color & {
    --content-width-narrow: 41.25rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: inherit;
    background-color: var(--box-color);
    padding: var(--spacing-regular);

    @include breakpoint-min(small) {
      padding: var(--spacing-large);
    }
  }
}

.hero {
  display: flex;
  align-items: center;
  inline-size: 100%;
  position: relative;
  justify-content: center;
  background-color: var(--background-color, var(--color-hg-gray1));
  background-color: hsl(
    from var(--background-color, var(--color-hg-gray1)) h s 10%
  );
  img {
    opacity: 1;
  }
}

.content {
  inline-size: 100%;
  block-size: 100%;
  max-inline-size: 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include portrait {
    aspect-ratio: 3 / 4;
  }

  @include landscape {
    aspect-ratio: 21 / 9;
  }
}

.grid {
  align-items: center;
  justify-content: center;
  block-size: 100%;
}

.title {
  max-inline-size: 20ch;
}

.title,
.description {
  .has-color & {
    text-align: center;
  }
}

.white-text {
  text-shadow: 0 0 0.25ch var(--color-text-shadow-black);
}

.description {
  --font-size: var(--font-size-body20);
  margin-block-start: var(--spacing-medium);
  margin-bottom: var(--spacing-xregular);
  max-inline-size: 56ch;
  .has-color & {
    --font-size: var(--font-size-body16);
    font-size: inherit;
  }
}
